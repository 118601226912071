$(function() {
	var i18nLanguage = localStorage.i18nLanguage;
	if(!i18nLanguage){
		i18nLanguage="en";
	}
	// console.log(i18nLanguage);
	jQuery.i18n.properties({
		name: 'strings', //资源文件名称
		path: 'i18n/', //资源文件路径
		mode: 'map', //用Map的方式使用资源文件中的值
		// debug: true,
		language: i18nLanguage,
		callback: function() {
			console.log("i18n赋值中...");
			// console.log($.i18n.prop('language'));
			try {
				//初始化页面元素
				$('[data-i18n-placeholder]').each(function() {
					$(this).attr('placeholder', $.i18n.prop($(this).data('i18n-placeholder')));
				});
				$('[data-i18n-text]').each(function() {
					//若是text里面还有html须要过滤掉
					var html = $(this).html();
					var reg = /<(.*)>/;
					if (reg.test(html)) {
						var htmlValue = reg.exec(html)[0];
						$(this).html(htmlValue + $.i18n.prop($(this).data('i18n-text')));
					} else {
						$(this).text($.i18n.prop($(this).data('i18n-text')));
					}
				});
				$('[data-i18n-value]').each(function() {
					$(this).val($.i18n.prop($(this).data('i18n-value')));
				});
				$('[data-i18n-html]').each(function() {
					$(this).html($.i18n.prop($(this).data('i18n-html')));
				});
				$('[data-i18n-after-html]').each(function() {
					var html = $(this).html();
					$(this).html($.i18n.prop($(this).data('i18n-after-html'))+html);
				});
			} catch (ex) {}
			console.log("i18n写入完毕");
		}
	});
});

export function loadLocalization(template) {
	template.find('[data-i18n-placeholder]').each(function () {
		$(this).attr('placeholder', $.i18n.prop($(this).data('i18n-placeholder')));
	});
	template.find('[data-i18n-text]').each(function () {
		//若是text里面还有html须要过滤掉
		var html = $(this).html();
		var reg = /<(.*)>/;
		if (reg.test(html)) {
			var htmlValue = reg.exec(html)[0];
			$(this).html(htmlValue + $.i18n.prop($(this).data('i18n-text')));
		} else {
			$(this).text($.i18n.prop($(this).data('i18n-text')));
		}
	});
	template.find('[data-i18n-value]').each(function () {
		$(this).val($.i18n.prop($(this).data('i18n-value')));
	});
	template.find('[data-i18n-html]').each(function () {
		$(this).html($.i18n.prop($(this).data('i18n-html')));
		// console.log($.i18n.prop($(this).data('i18n-html')));
	});
	template.find('[data-i18n-after-html]').each(function () {
		var html = $(this).html();
		$(this).html($.i18n.prop($(this).data('i18n-after-html')) + html);
	});
}
